<template>
  <div class="custom-select" :tabindex="tabindex">
    <div
      class="selected"
      :class="{ open: open }"
      @click="well_disabled ? null : (open = !open)"
    >
      {{ selected }}
      <span v-if="!well_disabled" class="well_drop_icon">
        <!-- <i class="fas fa-angle-down " ></i> -->
        <svg
          class="mt-2"
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="5"
          viewBox="0 0 12 8"
          fill="none"
        >
          <path
            d="M10.9999 2.59L6.70995 6.83C6.61699 6.92372 6.50638 6.99812 6.38453 7.04889C6.26267 7.09965 6.13196 7.12579 5.99995 7.12579C5.86794 7.12579 5.73723 7.09965 5.61537 7.04889C5.49351 6.99812 5.38291 6.92372 5.28995 6.83L1.04995 2.59C0.95622 2.49703 0.881826 2.38643 0.831057 2.26457C0.780288 2.14271 0.75415 2.01201 0.75415 1.88C0.75415 1.74798 0.780288 1.61728 0.831057 1.49542C0.881826 1.37356 0.95622 1.26296 1.04995 1.17C1.23731 0.983744 1.49076 0.879203 1.75495 0.879203C2.01913 0.879203 2.27259 0.983744 2.45995 1.17L5.99995 4.71L9.53995 1.17C9.72621 0.985251 9.97761 0.8811 10.2399 0.879995C10.3716 0.879234 10.502 0.904459 10.6239 0.954224C10.7457 1.00399 10.8565 1.07731 10.9499 1.17C11.047 1.25961 11.1254 1.36758 11.1805 1.48765C11.2356 1.60773 11.2664 1.73754 11.271 1.86957C11.2757 2.00161 11.2541 2.13325 11.2076 2.25691C11.161 2.38056 11.0905 2.49377 10.9999 2.59Z"
            fill="#727272"
          />
        </svg>
        <!-- <i class="fas fa-angle-down " v-if="!open"></i> -->
        <!-- <i class="fas fa-angle-up " v-else></i> -->
      </span>
    </div>
    <!-- :class="{ selectHide: !open }" -->
    <div class="items" v-if="open">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="optionClicked(option)"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: [String,Number],
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    well_disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
      rigname: "",
    };
  },
  methods: {
    optionClicked(option) {
      this.selected = option;
      this.open = false;
      this.$emit("input", option);
    },
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  watch: {
    default(newValue, oldValue) {
      this.selected = newValue;
    },
    options(newValue) {},
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  /* height: 47px; */
  /* line-height: 47px; */
  font-size: 10px;
  font-weight: 600;
  /* border-right: 0.5px solid #1b1a1f */
}

.custom-select .selected {
  /* background-color: #0a0a0a; */
  /* border-radius: 6px; */
  /* border: 1px solid #666666; */
  color: var(--colorBoxWhiteNum);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  font-size: 9.5px;
  font-weight: 400;
  display: flex;
  height: 25px;
  align-self: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trend_props.custom-select .selected{
  color: var(--colorBoxHeadingText);
}

.custom-select .selected.open {
  border: 1px solid var(--activeTextColor);
  /* border-radius: 6px 6px 0px 0px; */
  height: 25px;
  padding: 2px;
}

.custom-select .selected:after {
  position: absolute;
  /* content: ""; */
  top: 22px;
  right: 3em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  /* border-radius: 0px 0px 6px 6px; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* scrollbar-width: thin; */
  /* border-right: 1px solid #5aba81;
  border-left: 1px solid #5aba81;
  border-bottom: 1px solid #5aba81; */
  border-right: 1px solid var(--activeTextColor);
  border-left: 1px solid var(--activeTextColor);
  border-bottom: 1px solid var(--activeTextColor);
  /* position: absolute; */
  background-color: var(--root_nav);
  left: 0;
  right: 0;
  z-index: 1;
  font-size: 9.5px;
  top: 24px;
  font-weight: 400;
  max-height: 24rem;
}

.custom-select .items div {
  color: var(--colorBoxWhiteNum);
  padding: 3px 2em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  /* background-color: #5aba81; */
  background-color: var(--activeTextColor);
  color: #fff;
  font-weight: 600;
}

.selectHide {
  display: none;
}
.correlation_bar.custom-select .selected{
  display: flex;
  justify-content: space-between;
}
.fromDIS .items{
  position: absolute;
}
</style>
