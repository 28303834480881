<template>
    <div class="fixed zinindx overflow-y-auto top-0 w-full left-0 model_height model_bg" :id="'broomStick-modal'">
        <div
            class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 model_height">
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 bg-gray-900 opacity-75" />
            </div>
            <div class="container mx-auto px-4 h-full">
                <div class="flex content-center items-center justify-center h-full">
                    <div class="w-full lg:w-4/12 modal_width">
                        <div
                            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg_model border-0">
                            <div class="rounded-t mb-0 px-6 py-6">
                                <div class="text-center mb-3">

                                    <!-- <h6
                      v-if="!isAlreadySaved"
                      class="text-white text-md font-bold"
                    >
                      SAVE BHA RUN
                    </h6> -->
                                    <h6 class="text-white text-md font-bold">
                                        SELECT PUMP
                                    </h6>
                                </div>
                            </div>
                            <div class="flex-auto px-4 lg:px-10 py-10 pt-0 modal_body">
                                <div class="relative w-full mb-3 popup">
                                    <ul class="normal-pumps">
                                        <li
                                            class="text-white text-xs font-bold leading-relaxed text-xs whitespace-nowrap uppercase dropdown z-40 name-li">
                                            Normal
                                        </li>
                                        <li v-for="(pump, index) in normalPump" :key="'normal-' + index"
                                            class="text-white text-xs font-bold leading-relaxed text-xs whitespace-nowrap uppercase dropdown z-40 input-li p-3">
                                            <input class="m-0" :id="'normalPumpCheckbox-' + index" type="checkbox"
                                                :checked="isPumpSelected('normal', pump)"
                                                @change="handlePumpSelection('normal', pump)" />
                                            <label :for="'normalPumpCheckbox-' + index"
                                                class="ml-2 mr-2 font-bold input-label">
                                                PUMP - {{ pump }}
                                            </label>
                                        </li>
                                    </ul>

                                    <ul class="booster-pumps">
                                        <li
                                            class="text-white text-xs font-bold leading-relaxed text-xs whitespace-nowrap uppercase dropdown z-40 name-li">
                                            Booster
                                        </li>
                                        <li v-for="(pump, index) in boosterPump" :key="'booster-' + index"
                                            class="text-white text-xs font-bold leading-relaxed text-xs whitespace-nowrap uppercase dropdown z-40 input-li p-3">
                                            <input class="m-0" :id="'boosterPumpCheckbox-' + index" type="checkbox"
                                                :checked="isPumpSelected('booster', pump)"
                                                @change="handlePumpSelection('booster', pump)" />
                                            <label :for="'boosterPumpCheckbox-' + index"
                                                class="ml-2 mr-2 font-bold input-label">
                                                PUMP - {{ pump }}
                                            </label>
                                        </li>
                                    </ul>
                                </div>

                                <div class="text-center mt-6 flex">
                                    <button @click="this.$emit('cancelPopup')" type="button"
                                        class="px-4 bg-gray-500 text-white text-sm bg_cancel bg_modal_btns rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1">
                                        <i class="fas fa-times"></i> Cancel
                                    </button>

                                    <button @click="this.$emit('save', selectedPumps)"
                                        class="bg-green-600 bg_create bg_modal_btns text-white active:bg-green-800 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                                        type="button">
                                        <i class="fas fa-plus"></i> Save
                                    </button>
                                </div>
                                <!-- <div class="text-red-500">
                    {{ errcode }}
                  </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'boosterpump',
    emits: ['cancelPopup', 'save'],
    data() {
        return {
            isShow: false,
            normalPump: [1, 2, 3, 4, 5],
            boosterPump: [1, 2, 3, 4, 5],
            selectedPumps: {
                normal: [],
                booster: []
            }
        }
    },
    methods: {
        isPumpSelected(section, pump) {
            return this.selectedPumps[section].includes(pump);
        },
        handlePumpSelection(section, pump) {
            const selectedSection = section === 'normal' ? 'normal' : 'booster';
            const otherSection = section === 'normal' ? 'booster' : 'normal';

            if (this.selectedPumps[otherSection].includes(pump)) {
                // If pump is selected in the other section, unselect it
                const index = this.selectedPumps[otherSection].indexOf(pump);
                this.selectedPumps[otherSection].splice(index, 1);
            }

            // Toggle selection in the current section
            const isSelected = this.selectedPumps[selectedSection].includes(pump);
            if (isSelected) {
                // Remove pump from selected pumps
                const index = this.selectedPumps[selectedSection].indexOf(pump);
                this.selectedPumps[selectedSection].splice(index, 1);
            } else {
                // Add pump to selected pumps
                this.selectedPumps[selectedSection].push(pump);
            }
        }
    },
    mounted() {
        console.log('coming to mounted of boosterpump')
    },
}



</script>


<style scoped>
.popup {
    color: white !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.input-li {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;

}

.name-li {
    font-size: 10px !important;
    font-weight: bold;
    color: white;
    border: 1px solid gray;
}

.input-label {
    font-size: 10px !important;
    font-weight: 100;
    color: white;
}
</style>