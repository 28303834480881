<template>
  <!-- Navbar -->
  <div>
    <div id="header" class="hide_show w-full" :class="show ? 'height' : ''">
      <div class="w-full mx-auto bg_gray items-center">
        <div class="root_nav relative">
          <ul
            class="flex flex-col top_sub_nav lg:flex-row list-none lg:mr-auto relative"
            v-if="show"
          >
            <li
              class="flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase top_sub_nav dropdown z-40 theme_color_del_save_btn" 
            >
              <a
                href="#"
                class="textColorClass flex items-center  text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase top_sub_nav dropdown z-40"
                @click="toggleModal()"
              >
                <i class="fa fa-save" aria-hidden="true"></i>
              </a>

              <a
                href="#"
                class="textColorClass flex items-center  text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase top_sub_nav dropdown z-40 w-80 mr-6"
                style="width: 94%"
                @click="deleteWidget()"
              >
                <i class="fa fa-trash" aria-hidden="true"></i>
              </a>
            </li>
          </ul>

          <ul
            class="absolute w-100 right-0 z-40 hide_show_btn"
            style="top: -3px"
          >
            <li
              class="flex items-center text-xs font-bold leading-relaxed text_xs mr-2 py-1 whitespace-nowrap uppercase"
              :class="darkDark !== 'white'  ? 'text-white' : 'text-dark'"
            >
              <a href="#" @click="updateShow()">
                <p v-if="!show"><i class="fa fa-eye" aria-hidden="true"></i></p>
                <p :class="!show ? 'textColorClass' : 'textColorClass'" v-else>X</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Teleport to="body">
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden model_height model_bg"
      :id="'background-modal' + backgroundId"
    >
      <div
        class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0 model_height"
      >
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <div class="container mx-auto px-4 h-full">
          <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4 modal_width">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg_model border-0"
              >
                <div class="rounded-t mb-0 px-6 py-6">
                  <div class="text-center mb-3">
                    <h6
                      v-if="!isAlreadySaved"
                      class="text-white text-md font-bold"
                    >
                      Save Background
                    </h6>
                    <h6
                      v-if="isAlreadySaved"
                      class="text-white text-md font-bold"
                    >
                      Save Background As
                    </h6>
                  </div>
                </div>
                <div class="flex-auto px-4 lg:px-10 py-10 pt-0 modal_body">
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-white text-xs font-bold mb-2 label_"
                      htmlFor="grid-password"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      class="border-0 px-3 py-3 placeholder-blueGray-50 text-white bg_input rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      v-model="background_name"
                    />
                  </div>

                  <div class="text-center mt-6 flex">
                    <button
                      type="button"
                      class="px-4 bg-gray-500 text-white text-sm bg_cancel bg_modal_btns rounded hover:bg-gray-700 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      @click="toggleModal()"
                    >
                      <i class="fas fa-times"></i> Cancel
                    </button>

                    <button
                      class="bg-green-600 bg_create bg_modal_btns text-white active:bg-green-800 text-sm font-bold button uppercase px-6 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 py-1"
                      type="button"
                      @click="onSaveBackground()"
                    >
                      <i class="fas fa-plus"></i> Save
                    </button>
                  </div>
                  <div class="text-red-500">
                    {{ errcode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</Teleport>
  </div>
</template>
<script>
import api from "../../api/services";
export default {
  props: {
    onMinMaxBck: Function,
    isHideShowVisible: Function,
    displayId: String,
    widgetId: String,
    backgroundId: String,
    isMinimize: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  methods: {
    updateShow() {
      this.isHideShowVisible(!this.show);
      this.show = !this.show;
    },
    toggleModal() {
      this.errcode = "";
      this.background_name = "";
      if (
        document
          .getElementById("background-modal" + this.backgroundId)
          .classList.value.indexOf("hidden") > -1
      ) {
        for (let rect of this.$store.state.rect.rects) {
          if (rect["backgroundId"] == this.backgroundId) {
            if (rect.hasOwnProperty("savedId")) {
              this.isAlreadySaved = rect["savedId"];
              this.background_name = rect["savedName"];
            }
          }
        }
      }
      document
        .getElementById("background-modal" + this.backgroundId)
        .classList.toggle("hidden");
      // alert("model works")
    },
    async onSaveBackground() {
      if (!this.background_name) {
        this.$toast.error("Please enter a valid Background name", {
          position: "top",
          duration: "2000",
        });
        this.errcode = "Please enter a valid Background name";
        return {};
      }

      if (this.background_name.length > 14) {
        this.$toast.error("Background Name must be less than 15 characters", {
          position: "top",
          duration: "2000",
        });
        this.errcode = "Background Name must be less than 15 characters";
        return {};
      }

      let nameExists = await api.DashBoardServices.getNameExists({
        name: this.background_name,
      });

      if (nameExists && nameExists.data) {
        this.errcode = "NAME ALREADY EXISTS";
        this.$toast.error("NAME ALREADY EXISTS", {
          position: "top",
          duration: "2000",
        });
        return {};
      }

      let saveBg = {
        customerId: 1,
        name: this.background_name,
        dbId: null,
        dbType: "background", // 'Dashboard' / 'Screen' / 'Background'
        dashboard: null,
        screen: null,
        background: {
          name: "Background",
          widgets: [],
        },
      };
      for (let rect of this.$store.state.rect.rects) {
        // alert(rect['backgroundId'] + ' ' +rect['bgId'] +' '+ rect['name'])
        if (rect["backgroundId"] == this.backgroundId) {
          saveBg.background.left = rect.left;
          saveBg.background.top = rect.top;
          saveBg.background.color = rect.color;
          saveBg.background.zIndex = rect.zIndex;
          saveBg.background.width = rect.width;
          saveBg.background.height = rect.height;
        }
        if (rect["bgId"] == this.backgroundId) {
          saveBg.background.widgets.push({
            ...rect,
            name: rect.name, // Numeric / Table
            label: ["wob"], // eg : 'DBTM', [GTF, MTF]
            left: rect.left,
            top: rect.top,
            width: rect.width,
            height: rect.height,
            zIndex: rect.zIndex,
            color: rect.color,
          });
        }
      }
      if (this.isAlreadySaved) {
        saveBg.id = this.isAlreadySaved;
      }

      let response = await api.DashBoardServices.saveDashboard(saveBg);
      this.$store.dispatch("rect/chnageBackgroundName", {
        backgroundId: this.backgroundId,
        savedName: this.background_name,
      });

      this.toggleModal();
      this.$toast.success("Background Saved", {
        position: "top",
        duration: "2000",
      });
    },
    onMininmizeWidgetHandler() {
      this.$store.dispatch("rect/minimizeRect", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
    },
    deleteWidget() {
      this.$store.dispatch("rect/deleteWidget", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
    },
    onMinimizeHandler() {
      this.onMinMaxBck("maximize");

      this.$store.dispatch("disp/minimizeRect", { displayId: this.displayId });
    },
    onMaximizeHandler() {
      this.onMinMaxBck("maximize");

      this.$store.dispatch("disp/maximizeRect", { displayId: this.displayId });
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  data() {
    return {
      show: false,
      background_name: "",
      errcode: "",
      isAlreadySaved: false,
    };
  },
};
</script>

<style>
.bg_gray {
  background-color: #141414;
}

.bg_gray_600 {
  background-color: #2e2e2e;
}

.nav_bar {
  /* font-family: 'Poppins', sans-serif; */
  /* "Poppins"; */
  font-size: 14px;
  /* font-weight: 500; */
  color: #ffffff50;
}

.ml-6rem {
  margin-left: 6.6rem;
}

.ml-4rem {
  margin-left: 4.4rem;
}

.nav_item {
  padding: 0.8rem 0.2rem;
}

.nav_item.active {
  opacity: 1;
  border-bottom: 2px solid #47a08e;
  color: white;
}

.text_light_gray {
  color: #ffffff80;
}

.bg_gray_400 {
  background-color: #414141;
}

.nav_bar_dashboard {
  /* font-family: 'Poppins', sans-serif; */
  /* "Poppins"; */
  font-size: 12px;
  /* font-weight: 500; */
  color: #595959;
}

.mr_5 {
  margin-right: 2rem;
}

.mr_1 {
  margin-right: 1rem;
}

.nav_item_dashboard.active {
  color: white;
}

.text_light_gray {
  color: #959595;
}

.btn-dashboard-nav {
  background-color: #414141;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 11px;
  padding-top: 1px;
  padding-right: 0.75rem;
  padding-bottom: 1px;
  padding-left: 0.75rem;
  /* font-family: 'Poppins', sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
}

.dropdown-border-bottom {
  border-bottom: 1px solid #707070;
}

.dropdown_label {
  color: #ffffff70;
  /* font-family: 'Poppins', sans-serif; */
  /* "Poppins"; */
  /* font-weight: 400; */
  font-size: 14px;
}

.bg_dark_gray {
  background: #414141;
}

.top-16 {
  top: 3.9rem;
}

.text_xs {
  font-size: 8px;
  font-weight: 200;
}

.top_sub_nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 25px;
}

.root_nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: var(--root_nav) !important;
  transition:var(--transition)
}

.dropdown:hover .dropdown-menu {
  display: block !important;
  z-index: 999;
  width: 100%;
}

/* .modal_width{
  width: 22%;
}
.modal_body{
      font-size: 10px;
    padding: 0 15px 18px 15px;
} */
.hide_show {
  position: relative;
  z-index: 999999;
}
.theme_color_del_save_btn{
  color: var(--textColor);
}
</style>
